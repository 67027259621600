import axios from 'axios';
import { Media, UploadMedia } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const MEDIAS_URL = `${API_URL}/api/medias/`;

export function createMedia(filename: string, content_type: string | undefined, public_: boolean) {
  return axios.post<UploadMedia>(MEDIAS_URL, {
    name: filename,
    content_type: content_type,
    public: public_
  });
}

export function confirmMediaUpload(mediaId: string | unknown) {
  return axios.post<Media>(MEDIAS_URL + mediaId + '/confirm_upload/');
}
