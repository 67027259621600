import { useQuery } from 'react-query';
import { KTSVG } from '../../../_metronic/helpers';
import { DisableSidebar, EnableSidebar, PageTitle } from '../../../_metronic/layout/core';
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15
} from '../../../_metronic/partials/widgets';
import { useAuth } from '../../modules/auth';
import { DownloadGateListCard } from '../../modules/gates/components/DownloadGateListCard';
import { getGateGlobalMetrics, listGates } from '../../modules/gates/core/_requests';

const DashboardPage = function () {
  const { data: globalMetrics, status } = useQuery(['gateGlobalMetrics'], async () => {
    const { data: globalMetrics } = await getGateGlobalMetrics();
    return globalMetrics;
  });

  return (
    <>
      <div className="row gy-5 g-xl-10">
        {/*begin::Col*/}
        {/* <div className="col-xl-4">
        <MixedWidget13
          className="card-xl-stretch mb-xl-10"
          backGroundColor="#F7D9E3"
          chartHeight="100px"
        />
      </div> */}
        {/*end::Col*/}

        {/*begin::Col*/}
        {/* <div className="col-xl-4">
        <MixedWidget14
          className="card-xl-stretch mb-xl-10"
          backGroundColor="#CBF0F4"
          chartHeight="100px"
        />
      </div> */}
        {/*end::Col*/}

        {/*begin::Col*/}
        {/* <div className="col-xl-4">
        <MixedWidget15 className="" backGroundColor="#CBD4F4" />
      </div> */}
        {/*end::Col*/}

        {status === 'success' && (
          <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-10">
              <div className="card-body d-flex flex-column">
                <div className="d-flex flex-column mb-7">
                  <span className="text-dark text-hover-primary fw-bolder fs-3">Summary</span>
                </div>
                <div className="row g-0">
                  <div className="col-6">
                    <div className="d-flex align-items-center mb-9 me-2">
                      <div className="symbol symbol-40px me-3">
                        <div className="symbol-label bg-white bg-opacity-50">
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            className="svg-icon-1 svg-icon-dark"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="fs-5 text-dark fw-bolder lh-1">
                          {globalMetrics.total_visits.toLocaleString()}
                        </div>
                        <div className="fs-7 text-gray-600 fw-bold">total views</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center mb-9 ms-2">
                      <div className="symbol symbol-40px me-3">
                        <div className="symbol-label bg-white bg-opacity-50">
                          <KTSVG
                            path="/media/icons/duotune/files/fil021.svg"
                            className="svg-icon-1 svg-icon-dark"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="fs-5 text-dark fw-bolder lh-1">
                          {globalMetrics.total_completed_downloads.toLocaleString()}
                        </div>
                        <div className="fs-7 text-gray-600 fw-bold">total downloads</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center me-2">
                      <div className="symbol symbol-40px me-3">
                        <div className="symbol-label bg-white bg-opacity-50">
                          <KTSVG
                            path="/media/icons/duotune/coding/cod001.svg"
                            className="svg-icon-1 svg-icon-dark"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="fs-5 text-dark fw-bolder lh-1">
                          {globalMetrics.total_actions.toLocaleString()}
                        </div>
                        <div className="fs-7 text-gray-600 fw-bold">executed actions</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-6">
                <div className="d-flex align-items-center ms-2">
                  <div className="symbol symbol-40px me-3">
                    <div className="symbol-label bg-white bg-opacity-50">
                      <KTSVG
                        path="/media/icons/duotune/abstract/abs045.svg"
                        className="svg-icon-1 svg-icon-dark"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="fs-5 text-dark fw-bolder lh-1">$5.8M</div>
                    <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/*end::Row*/}

      <DownloadGateListCard limit={5}></DownloadGateListCard>

      {/* <div className="row gy-5 g-xl-10">
      <div className="col-xxl-6">
        <ListsWidget5 className="card-xl-stretch mb-xl-10" />
      </div>
      <div className="col-xxl-6">
        <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-10" items={5} />
      </div>
    </div> */}
    </>
  );
};

const DashboardWrapper = () => {
  const { currentUser } = useAuth();

  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>{'Hello ' + currentUser?.first_name + ','}</PageTitle>
      <DashboardPage />
    </DisableSidebar>
  );
};

export { DashboardWrapper };
