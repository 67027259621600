/* eslint-disable jsx-a11y/anchor-is-valid */
import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ListRowSkeleton } from '../core/_helpers';
import { DownloadGateList } from './DownloadGateList';

export function DownloadGateListCard({ limit }: { limit?: number }) {
  return (
    <>
      <div className="card">
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            {limit && (
              <span className="card-label fw-bold fs-3 mb-1">Your last {limit} download gates</span>
            )}
            {!limit && <span className="card-label fw-bold fs-3 mb-1">Your download gates</span>}
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>subtitle here</span> */}
          </h3>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-primary" to="/gates/new/">
              Create new gate
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          <Suspense fallback={ListRowSkeleton()}>
            <DownloadGateList limit={limit}></DownloadGateList>
          </Suspense>
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
}
