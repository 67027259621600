/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownloadGateListCard } from './DownloadGateListCard';

export function Overview() {
  return (
    <>
      <DownloadGateListCard></DownloadGateListCard>
    </>
  );
}
