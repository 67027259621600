import { createRoot } from 'react-dom/client';
// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';

import * as Sentry from '@sentry/react';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://e304f272457f463098d99eab1076ae5c@o1079597.ingest.sentry.io/4504398071660544',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);

Chart.register(...registerables);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true
    }
  }
});
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
