import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import AwsS3 from '@uppy/aws-s3';
import { confirmMediaUpload, createMedia } from '../../../media/core/_requests';
import { firstValueFrom, from, mergeMap, of } from 'rxjs';
import { AxiosResponse } from 'axios';
import { Media, UploadMedia } from '../../../media/core/_models';
import { useEffect, useState } from 'react';

import '../styles/Step1.css';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { gateQuery } from '../../core/Gate';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { deleteGateFile, setGateFile } from '../../core/_requests';

const uppy = new Uppy({
  autoProceed: true,
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['.mp3', '.wav']
  },
  debug: true
});

uppy.use(AwsS3, {
  getUploadParameters: (file) => {
    return firstValueFrom(
      from(createMedia(file.name, file.type, false)).pipe(
        mergeMap((media: AxiosResponse<UploadMedia>, index) => {
          file.meta['media_id'] = media.data.media.id;

          return of({
            method: 'POST',
            url: media.data.upload_url.url,
            fields: media.data.upload_url.fields,
            headers: {}
          });
        })
      )
    );
  }
});

const initialValues = {
  title: '',
  description: null,
  musical_genre_id: null
};

export function Step2() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const downloadGate = useRecoilValue(gateQuery(Number(id)));
  const refreshDownloadGate = useRecoilRefresher_UNSTABLE(gateQuery(Number(id)));
  const navigate = useNavigate();

  useEffect(() => {
    uppy.on('upload-success', (result) => {
      if (result) {
        confirmMediaUpload(result.meta.media_id).then((res) => {
          setGateFile(Number(id), result.meta.media_id as number).then((res) => {
            refreshDownloadGate();
          });
        });
      }
    });
  });

  function deleteFile(mediaId: number) {
    deleteGateFile(Number(id), mediaId).then((res) => {
      let files = uppy.getFiles();
      if (files.length > 0) {
        uppy.removeFile(uppy.getFiles()[0].id);
      }
      refreshDownloadGate();
    });
  }

  return (
    <div className="row">
      <div className="col min-vh-50">
        <p className="fs-2 fw-bold mb-10">Your files</p>
        {!downloadGate?.attached_file && (
          <div>
            <p>Upload your file just below using drag & drop or by clicking on the selector.</p>
            <Dashboard
              width="100%"
              height="100%"
              note="Drop your file here or click to upload"
              uppy={uppy}
              hideRetryButton={true}
              hideCancelButton={true}
              hidePauseResumeButton={true}
              hideUploadButton={true}
            ></Dashboard>
          </div>
        )}

        {downloadGate?.attached_file != null && (
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="align-middle ps-4 rounded-start">Name</th>
                  <th className="align-middle">Date uploaded</th>
                  <th className="align-middle min-w-150px rounded-end">Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td className="text-center text-muted">{downloadGate.attached_file.name}</td>
                  <td>{DateTime.fromISO(downloadGate.attached_file.created).toLocaleString()}</td>
                  <td>
                    <button
                      onClick={() => {
                        deleteFile(downloadGate.attached_file!.id!);
                      }}
                      className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
      </div>
      <div className="row mt-10">
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={downloadGate?.attached_file == null}
            onClick={() => {
              navigate('/gates/new/' + downloadGate!.id + '/steps');
            }}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
