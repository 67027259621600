import { Doughnut } from 'react-chartjs-2';
import { DownloadGate } from '../../core/_models';
import Chart from 'react-apexcharts';
import { KTUtil } from '../../../../../_metronic/assets/ts';
import { getGateMetrics, getGatePlatformMetrics } from '../../core/_requests';
import { useQuery } from 'react-query';
import { Suspense, useEffect, useState } from 'react';

export function Overview(props: { downloadGate: DownloadGate }) {
  const downloadGate = props.downloadGate;

  var t = KTUtil.getCSSVariableValue('--kt-primary'),
    e = KTUtil.getCSSVariableValue('--kt-primary-light'),
    a = KTUtil.getCSSVariableValue('--kt-success'),
    r = KTUtil.getCSSVariableValue('--kt-success-light'),
    o = KTUtil.getCSSVariableValue('--kt-gray-200'),
    n = KTUtil.getCSSVariableValue('--kt-gray-500');

  const state = {
    options: {
      chart: {
        stacked: false,
        toolbar: {
          show: !1
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px'
        },
        offsetY: 0
      },
      xaxis: {
        tooltip: { enabled: false }
      },
      colors: [t, a],
      fill: {
        type: 'gradient',
        opacity: 1
      },
      legend: {
        show: false
      }
    }
  };

  const [gateMetricsSeries, setGateMetricsSeries] = useState<{ name: string; data: number[] }[]>(
    []
  );
  const [gatePlatformMetricsSeries, setGatePlatformMetricsSeries] = useState<{
    labels: string[];
    datasets: { data: number[]; borderWidth: number; backgroundColor: string[] }[];
  }>({ labels: [], datasets: [] });

  const { data: downloadGateMetrics, status: metricsStatus } = useQuery(
    ['gateMetrics', downloadGate.id],
    async ({ queryKey }) => {
      const { data: downloadGateMetrics } = await getGateMetrics(queryKey[1]!.toString());
      return downloadGateMetrics;
    }
  );

  const { data: downloadGatePlatformMetrics, status: platformMetricsStatus } = useQuery(
    ['gatePlatformMetrics', downloadGate.id],
    async ({ queryKey }) => {
      const { data: downloadGatePlatformMetrics } = await getGatePlatformMetrics(
        queryKey[1]!.toString()
      );
      return downloadGatePlatformMetrics;
    }
  );

  useEffect(() => {
    if (metricsStatus === 'success') {
      const downloadSeries: any[] = [];
      const visitSeries: any[] = [];

      for (let data of downloadGateMetrics.metrics) {
        visitSeries.push({ x: data.date, y: data.visits });
        downloadSeries.push({ x: data.date, y: data.downloads });
      }

      setGateMetricsSeries([
        { name: 'Page views', data: visitSeries },
        { name: 'Downloads', data: downloadSeries }
      ]);
    }
  }, [downloadGateMetrics]);

  useEffect(() => {
    if (platformMetricsStatus === 'success') {
      const labels: any[] = [];
      const colors: any[] = [];
      const data: any[] = [];

      for (let platform of downloadGatePlatformMetrics.platforms) {
        labels.push(platform.name);
        colors.push(platform.color);
        data.push(platform.count);
      }

      setGatePlatformMetricsSeries({
        labels: labels,
        datasets: [
          {
            data: data,
            borderWidth: 0,
            backgroundColor: colors
          }
        ]
      });
    }
  }, [downloadGatePlatformMetrics]);

  return (
    <>
      <div className="row mb-10">
        <div className="col">
          <div className="card card-flush h-lg-100">
            <div className="card-header mt-6">
              <div className="card-title flex-column">
                <h3 className="fw-bold mb-1">Activity over time</h3>
                <div className="fs-6 fw-semibold text-gray-400">
                  See your download gate activity over time
                </div>
              </div>
            </div>
            <div className="card-body py-3">
              <div className="fs-6 d-flex text-gray-400 fs-6 fw-semibold">
                <div className="d-flex align-items-center me-6">
                  <span className="menu-bullet d-flex align-items-center me-2">
                    <span className="bullet bg-primary"></span>
                  </span>
                  Page views
                </div>
                <div className="d-flex align-items-center">
                  <span className="menu-bullet d-flex align-items-center me-2">
                    <span className="bullet bg-success"></span>
                  </span>
                  Unique downloads
                </div>
              </div>
              <div className="mixed-chart h-250px">
                <Chart
                  options={state.options}
                  series={gateMetricsSeries}
                  type="area"
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-flush h-lg-100">
            {/* begin::Header */}
            <div className="card-header mt-6">
              <div className="card-title flex-column">
                <h3 className="fw-bold mb-1">Platforms overview</h3>
                <div className="fs-6 fw-semibold text-gray-400">
                  Understand how your fans are using your download gate
                </div>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body py-3">
              <Suspense fallback={<div>Loading...</div>}>
                <div className="d-flex flex-wrap">
                  <div className="position-relative d-flex flex-center h-175px w-175px me-15 mb-7">
                    <div className="position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center">
                      <span className="fs-2qx fw-bold">{downloadGatePlatformMetrics?.total}</span>
                      <span className="fs-6 fw-semibold text-gray-400">actions</span>
                    </div>
                    {platformMetricsStatus == 'success' && (
                      <Doughnut
                        data={gatePlatformMetricsSeries}
                        options={{
                          responsive: !0,
                          maintainAspectRatio: !1,
                          cutout: '75%',
                          animation: {
                            animateScale: !0,
                            animateRotate: !0
                          },
                          plugins: {
                            legend: {
                              display: !1
                            },
                            tooltip: {
                              enabled: !0,
                              intersect: !1,
                              mode: 'nearest',
                              bodySpacing: 5,
                              padding: 10,
                              caretPadding: 0,
                              displayColors: !1,
                              cornerRadius: 4,
                              footerSpacing: 0,
                              titleSpacing: 0
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                    {downloadGatePlatformMetrics!.platforms.map((platform) => (
                      <div
                        className="d-flex fs-6 fw-semibold align-items-center mb-3"
                        key={platform.name}>
                        <div
                          className="bullet me-3"
                          style={{ backgroundColor: platform.color }}></div>
                        <div className="text-gray-400">{platform.name}</div>
                        <div className="ms-auto fw-bold text-gray-700">{platform.count}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Suspense>
            </div>
            {/* begin::Body */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-xl theme-dark-bg-body" style={{ backgroundColor: '#CBF0F4' }}>
            <div className="card-body d-flex flex-column">
              <div className="d-flex flex-column flex-grow-1">
                <h3 className="text-dark fw-bold fs-3">
                  {downloadGate.musical_genre
                    ? downloadGate.musical_genre?.name + ' music'
                    : 'Music'}{' '}
                  charts
                </h3>
                <div className="fs-6 fw-semibold text-dark">
                  See how your track is charted compared to other tracks in the same category
                </div>
                <div className="h-175px"></div>
              </div>
              <div className="pt-5">
                <span className="text-dark text-center fw-bold fs-2x">
                  Unfortunately,{' '}
                  {downloadGate.musical_genre && downloadGate.musical_genre?.name + ' music '}charts
                  are currently unavailable
                </span>
              </div>
              {/* <div className="pt-5">
                <span className="text-dark fw-bold fs-3x lh-0">#</span>
                <span className="text-dark fw-bold fs-5x me-2 lh-0">560</span>
                <span className="text-dark fw-bold fs-6 lh-0">+ 28 this week</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
