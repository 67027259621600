export function StepperNavigation() {
  return (
    <div className="stepper-nav flex-center flex-wrap mb-10">
      <div className="stepper-item mx-8 my-4" data-kt-stepper-element="nav">
        <div className="stepper-wrapper d-flex align-items-center">
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">1</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Step 1</h3>

            <div className="stepper-desc">Basic informations</div>
          </div>
        </div>
        <div className="stepper-line h-40px"></div>
      </div>
      <div className="stepper-item mx-8 my-4" data-kt-stepper-element="nav">
        <div className="stepper-wrapper d-flex align-items-center">
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">2</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Step 2</h3>

            <div className="stepper-desc">Add your files</div>
          </div>
        </div>
        <div className="stepper-line h-40px"></div>
      </div>
      <div className="stepper-item mx-8 my-4" data-kt-stepper-element="nav">
        <div className="stepper-wrapper d-flex align-items-center">
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">3</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Step 3</h3>

            <div className="stepper-desc">Download gate steps</div>
          </div>
        </div>
        <div className="stepper-line h-40px"></div>
      </div>
      {/* <div className="stepper-item mx-8 my-4" data-kt-stepper-element="nav">
        <div className="stepper-wrapper d-flex align-items-center">
          <div className="stepper-icon w-40px h-40px">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">3</span>
          </div>
          <div className="stepper-label">
            <h3 className="stepper-title">Step 3</h3>

            <div className="stepper-desc">Marketing</div>
          </div>
        </div>
        <div className="stepper-line h-40px"></div>
      </div> */}
    </div>
  );
}
