/* eslint-disable jsx-a11y/anchor-is-valid */
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function ListRowSkeleton() {
  return (
    <>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table align-middle gs-0 gy-4">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bold text-muted bg-light">
              <th className="ps-4 rounded-start"></th>
              <th>Title</th>
              <th>Views count</th>
              <th>Downloads count</th>
              <th>Conversion</th>
              <th className="min-w-150px rounded-end">Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            <tr>
              <td>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-50px me-5">
                    <span className="symbol-label bg-light">
                      <Skeleton width={50} height={50} />
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <Skeleton width={90} />
                <Skeleton width={50} />
              </td>
              <td>
                <Skeleton width={50} />
              </td>
              <td>
                <Skeleton width={50} />
              </td>
              <td>
                <Skeleton width={50} />
              </td>
              <td>
                <Skeleton width={100} height={30} />
              </td>
            </tr>
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
    </>
  );
}
