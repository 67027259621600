/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Subject, debounceTime, distinctUntilChanged, fromEvent, switchMap } from 'rxjs';
import { addGateSteps, stepResolver } from '../core/_requests';
import { DownloadGate, DownloadGateAction } from '../core/_models';

export function CreateGateStepModal(props: {
  show: boolean;
  close: () => void;
  downloadGate: DownloadGate;
}) {
  const linkChanged = new Subject();
  const close = props.close;
  const downloadGate = props.downloadGate;
  const [currentLink, setCurrentLink] = useState(null);
  const [resolvedSteps, setResolvedSteps] = useState(
    [] as { checked: number; action: DownloadGateAction }[]
  );
  const [selectedSteps, setSelectedSteps] = useState([] as DownloadGateAction[]);

  async function onLinkChange(new_link: any) {
    if (new_link === '') {
      setCurrentLink(null);
      return;
    }

    const resolved = await stepResolver(Number(downloadGate.id), new_link);
    setResolvedSteps(
      resolved.data.map((action: DownloadGateAction) => {
        return { checked: 1, action };
      })
    );
    setSelectedSteps(resolved.data);
    setCurrentLink(new_link);
  }

  useEffect(() => {
    linkChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe(onLinkChange);
  });

  const handleCheck = (step: { checked: number; action: DownloadGateAction }) => {
    var updatedList = [...selectedSteps];
    if (step.checked) {
      updatedList = [...selectedSteps, step.action];
    } else {
      updatedList.splice(selectedSteps.indexOf(step.action), 1);
    }
    setSelectedSteps(updatedList);
  };

  async function save() {
    await addGateSteps(Number(downloadGate.id), selectedSteps);
    setCurrentLink(null);
    setResolvedSteps([]);
    close();
  }

  function show() {
    setCurrentLink(null);
    setResolvedSteps([]);
  }

  return (
    <Modal show={props.show} onHide={props.close} onShow={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add a download gate step</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          To add steps to your download gate, simply paste your track links or social links in the
          input just below.
        </p>
        <p>
          It will by default show you all the available gate steps available for the link you
          pasted.
        </p>

        <div className="row">
          <div className="col">
            <label className="form-label">Your track link:</label>
            <input
              type="text"
              className="form-control input-lg"
              placeholder="https://www.soundcloud.com/..."
              onChange={(e) => {
                linkChanged.next(e.target.value);
              }}
            />
          </div>
        </div>

        {currentLink && (
          <div className="table-responsive mt-5">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="align-middle ps-4 rounded-start"></th>
                  <th className="align-middle">Action</th>
                  <th className="align-middle">Account / Link</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {resolvedSteps.map((step, index) => (
                  <tr key={index}>
                    <td>
                      <div className="m-auto form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={step.checked}
                          checked={!!step.checked}
                          onChange={(e) => {
                            step.checked = e.target.checked ? 1 : 0;
                            handleCheck(step);
                          }}
                        />
                      </div>
                    </td>
                    <td>{step.action.action}</td>
                    <td>
                      <a href={step.action.action_parameters.display_link} target="_blank">
                        {step.action.action_parameters.display_name}
                      </a>
                    </td>
                  </tr>
                ))}

                {resolvedSteps.length == 0 && (
                  <tr>
                    <td colSpan={3} className="text-center text-muted">
                      No actions found
                      <p className="mt-5">
                        Current supported links are : Soundcloud, Spotify, Twitter
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={save} disabled={selectedSteps.length == 0}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
