import axios from 'axios';
import { DownloadGate, PublicDownloadGate } from '../../../../app/modules/gates/core/_models';
import { DownloadSession, DownloadSessionCreate } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const DOWNLOAD_SESSIONS_URL = `${API_URL}/api/download_sessions/`;
export const GATES_URL = `${API_URL}/api/download_gates/`;
export const LIST_MUSICAL_GENRES = `${API_URL}/api/musical_genres/`;

export function logVisit(slug: string, fingerprint: string, user_agent: string) {
  return axios.post<any>(GATES_URL + slug + '/visit/', {
    fingerprint,
    user_agent
  });
}

export function createDownloadSession(slug: string) {
  return axios.post<DownloadSessionCreate>(DOWNLOAD_SESSIONS_URL, { download_gate_slug: slug });
}

export function getDownloadSession(uid: string) {
  return axios.get<DownloadSession>(DOWNLOAD_SESSIONS_URL + uid + '/');
}

export function getDownloadSessionDownloadLink(uid: string) {
  return axios.get<{ url: string }>(DOWNLOAD_SESSIONS_URL + uid + '/download_link/');
}

export function getPublicDownloadGate(slug: string) {
  return axios.get<PublicDownloadGate>(GATES_URL + 'public/?slug=' + slug);
}

export function getDownloadGate(slug: string) {
  return axios.get<PublicDownloadGate>(GATES_URL + slug + '/');
}

// OAUTH

export function generateDownloadSessionOAuthRedirect(uid: string, oauth_provider: string) {
  return axios.get<any>(DOWNLOAD_SESSIONS_URL + uid + '/oauth_redirect_uri/', {
    params: { oauth_provider }
  });
}

export function confirmOAuth(uid: string, oauth_provider: string, code: string) {
  return axios.post<any>(DOWNLOAD_SESSIONS_URL + uid + '/oauth_confirm/', {
    oauth_provider,
    code
  });
}
