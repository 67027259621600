/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownloadGateListItem } from './DownloadGateListItem';
import { useQuery } from 'react-query';
import { listGates } from '../core/_requests';

export function DownloadGateList({ limit }: { limit?: number }) {
  const { data: gateList, status } = useQuery(['gateList', limit], async () => {
    const { data: gateList } = await listGates(limit);
    return gateList.results;
  });

  return (
    <>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table align-middle gs-0 gy-4">
          {/* begin::Table head */}
          <thead>
            <tr className="fw-bold text-muted bg-light">
              <th className="align-middle ps-4 rounded-start"></th>
              <th className="align-middle">Title</th>
              <th className="align-middle">Unique views count</th>
              <th className="align-middle">Downloads count</th>
              <th className="align-middle">Conversion</th>
              <th className="align-middle min-w-150px rounded-end">Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {status == 'success' &&
              gateList.length > 0 &&
              gateList.map((gate) => <DownloadGateListItem key={gate.id} gate={gate} />)}

            {status == 'success' && gateList.length === 0 && (
              <tr>
                <td colSpan={6} className="text-center text-muted">
                  <em>You don't have any download gate yet.</em>
                </td>
              </tr>
            )}

            {status != 'success' && (
              <tr>
                <td colSpan={6} className="text-center text-muted">
                  <em>An error occured while fetching data.</em>
                </td>
              </tr>
            )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
    </>
  );
}
