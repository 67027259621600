/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Suspense } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App as DashboardApp } from '../App';
import { App as PublicApp } from '../../public/App';
import { PublicGatePage } from '../../public/modules/gates/PublicGatePage';
import { RecoilRoot } from 'recoil';
import { PublicDownloadSessionPage } from '../../public/modules/gates/PublicDownloadSessionPage';
import { PublicOAuthRedirectPage } from '../../public/modules/gates/PublicOAuthRedirectPage';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<DashboardApp />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
        <Route element={<PublicApp />}>
          <Route
            path="tracks/:slug"
            element={
              <>
                <PublicGatePage />
              </>
            }
          />
          <Route
            path="download/:uid"
            element={
              <>
                <PublicDownloadSessionPage />
              </>
            }
          />
          <Route
            path="oauth/:oauth_provider"
            element={
              <>
                <PublicOAuthRedirectPage />
              </>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
