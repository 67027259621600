import { selector } from 'recoil';
import { listMusicalGenres } from './_requests';

export const musicalCategoriesListQuery = selector({
  key: 'musicalCategoriesListState',
  get: async () => {
    const { data: musicalCategories } = await listMusicalGenres();
    return musicalCategories;
  }
});
