import { selector, selectorFamily } from 'recoil';
import { getGate, listGateStep, listGates } from './_requests';
import { DownloadGate } from './_models';

export const gateQuery = selectorFamily({
  key: 'gateState',
  get:
    (id: number | undefined) =>
    async ({ get }) => {
      if (id === undefined) return null;
      const { data: gate } = await getGate(id);
      return gate;
    }
});

export const listGateStepQuery = selectorFamily({
  key: 'listGateStepState',
  get:
    (id: number | undefined) =>
    async ({ get }) => {
      if (id === undefined) return null;
      const { data: gateSteps } = await listGateStep(id);
      return gateSteps;
    }
});
