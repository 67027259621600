import { Suspense, useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { deleteGateStep, disableGateStep, enableGateStep } from '../core/_requests';
import { gateQuery, listGateStepQuery } from '../core/Gate';
import { DownloadGate, DownloadGateAction } from '../core/_models';
import Skeleton from 'react-loading-skeleton';
import { CreateGateStepModal } from './CreateGateStepModal';

export function ListGateStep(props: { downloadGate: DownloadGate; title: boolean }) {
  const downloadGate = props.downloadGate;
  let title = props.title;

  if (typeof title === 'undefined') {
    title = true;
  }

  const downloadGateSteps = useRecoilValue(listGateStepQuery(Number(downloadGate.id)));
  const refreshDownloadGateSteps = useRecoilRefresher_UNSTABLE(
    listGateStepQuery(Number(downloadGate.id))
  );

  const [showAddStep, setShowAddStep] = useState(false);

  async function handleDeleteGateStep(gateStepId: number) {
    await deleteGateStep(Number(downloadGate.id), gateStepId);
    refreshDownloadGateSteps();
  }

  async function onEnableGateStep(gateStepId: number) {
    await enableGateStep(Number(downloadGate.id), gateStepId);
    refreshDownloadGateSteps();
  }

  async function onDisableGateStep(gateStepId: number) {
    await disableGateStep(Number(downloadGate.id), gateStepId);
    refreshDownloadGateSteps();
  }

  return (
    <div className="row">
      <div className="col min-vh-50">
        {title && <p className="fs-2 fw-bold mb-10">Your fangate steps</p>}
        <div className="row">
          <div className="col">
            <button className="btn btn-primary float-end" onClick={() => setShowAddStep(true)}>
              <i className="bi bi-plus-circle"></i>
              Add new step
            </button>
          </div>
        </div>
        <div className="table-responsive mt-5">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted bg-light">
                <th className="align-middle ps-4 rounded-start">Action</th>
                <th className="align-middle ps-4">Account / Link</th>
                <th className="align-middle">Enabled</th>
                <th className="align-middle min-w-150px rounded-end">Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <Suspense
                fallback={
                  <tr>
                    <td colSpan={4}>
                      <Skeleton width={90} />
                    </td>
                  </tr>
                }
              >
                {downloadGateSteps?.map((step: DownloadGateAction) => (
                  <tr key={step.id}>
                    <td className="ps-4">{step.action}</td>
                    <td>
                      <a href={step.action_parameters.display_link} target="_blank">
                        {step.action_parameters.display_name}
                      </a>
                    </td>
                    <td>
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={step.enabled ? '1' : '0'}
                          checked={step.enabled}
                          onChange={() => {
                            if (step.enabled) {
                              onDisableGateStep(step.id!);
                            } else {
                              onEnableGateStep(step.id!);
                            }
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-light btn-active-light-danger"
                        onClick={() => {
                          handleDeleteGateStep(step.id!);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
                {downloadGateSteps?.length == 0 && (
                  <tr>
                    <td colSpan={4} className="text-center text-muted">
                      <em>There is no download gate step. You should consider to add one.</em>
                    </td>
                  </tr>
                )}
              </Suspense>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
      <CreateGateStepModal
        show={showAddStep}
        downloadGate={downloadGate!}
        close={() => {
          setShowAddStep(false);
          refreshDownloadGateSteps();
        }}
      ></CreateGateStepModal>
    </div>
  );
}
