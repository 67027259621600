import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import { useState } from 'react';

import { useRecoilValue } from 'recoil';
import { gateQuery, listGateStepQuery } from '../../core/Gate';
import { useNavigate, useParams } from 'react-router-dom';

import { ListGateStep } from '../ListGateStep';

export function Step3() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const downloadGate = useRecoilValue(gateQuery(Number(id)))!;
  const downloadGateSteps = useRecoilValue(listGateStepQuery(Number(id)));
  const navigate = useNavigate();

  return (
    <div className="row">
      <ListGateStep downloadGate={downloadGate} title={true} />
      <div className="row mt-10">
        <div className="col-12">
          <button
            type="submit"
            onClick={() => navigate('/gates/view/' + downloadGate!.id)}
            className="btn btn-primary float-end"
            disabled={downloadGateSteps.length == 0}
          >
            {!loading && <span className="indicator-label">Finish</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
