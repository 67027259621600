import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/users/me/`;
export const LOGIN_URL = `${API_URL}/api/token/`;
export const REGISTER_URL = `${API_URL}/api/users/`;
export const REQUEST_PASSWORD_URL = `${API_URL}/api/users/forget_password/`;
export const RESET_PASSWORD_URL = `${API_URL}/api/users/reset_password/`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password
  });
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    username: email,
    first_name: firstname,
    last_name: lastname,
    password
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  });
}

export function resetPassword(token: string | null, new_password: string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    token,
    new_password
  });
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}
