/* eslint-disable jsx-a11y/anchor-is-valid */

import { StepperNavigation } from './create_components/StepperNavigation';
import { Route, Routes } from 'react-router-dom';
import { Step1 } from './create_components/Step1';
import { Step2 } from './create_components/Step2';
import { Step3 } from './create_components/Step3';

export function CreateGate() {
  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">New download gate</h3>
        </div>
      </div>

      <div className="card-body border-top p-9">
        <div className="stepper stepper-pills last">
          <StepperNavigation></StepperNavigation>
        </div>

        <div className="mb-5">
          <div className="flex-column">
            <Routes>
              <Route index element={<Step1></Step1>} />
              <Route path="/:id/files" element={<Step2></Step2>} />
              <Route path="/:id/steps" element={<Step3></Step3>} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
