import axios from 'axios';
import {
  DownloadGate,
  DownloadGateAction,
  MusicalGenreCategory,
  PaginatedResponse
} from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GATES_URL = `${API_URL}/api/download_gates/`;
export const LIST_MUSICAL_GENRES = `${API_URL}/api/musical_genres/`;

export function listGates(limit: number = 500) {
  // if limit is undefined, sets it to 500
  if (!limit) {
    limit = 500;
  }

  return axios.get<PaginatedResponse<DownloadGate>>(GATES_URL + '?limit=' + limit);
}

export function listMusicalGenres() {
  return axios.get<MusicalGenreCategory[]>(LIST_MUSICAL_GENRES);
}

export function getGate(id: number) {
  return axios.get<DownloadGate>(GATES_URL + id + '/');
}

export function getGateGlobalMetrics() {
  return axios.get<{
    total_completed_downloads: number;
    total_visits: number;
    total_actions: number;
  }>(GATES_URL + 'metrics/');
}

export function getGateMetrics(id: string) {
  return axios.get<{ metrics: { date: string; downloads: number; visits: number }[] }>(
    GATES_URL + id + '/metrics/'
  );
}

export function getGatePlatformMetrics(id: string) {
  return axios.get<{ total: number; platforms: { name: string; count: number; color: string }[] }>(
    GATES_URL + id + '/platform_metrics/'
  );
}

export function createGate(data: DownloadGate) {
  return axios.post<DownloadGate>(GATES_URL, data);
}

export function updateGate(id: string | number, data: DownloadGate) {
  return axios.patch<DownloadGate>(GATES_URL + id + '/', data);
}

export function setGateFile(id: number, media_id: number) {
  return axios.post<DownloadGate>(GATES_URL + id + '/add_media/', { media_id });
}

export function deleteGateFile(id: number, media_id: number) {
  return axios.post(GATES_URL + id + '/delete_media/', { media_id });
}

export function stepResolver(id: number, link: string) {
  return axios.post<DownloadGateAction[]>(GATES_URL + id + '/actions/resolve/', { link });
}

export function listGateStep(id: number) {
  return axios.get(GATES_URL + id + '/actions/');
}

export function addGateSteps(id: number, actions: DownloadGateAction[]) {
  return axios.post(GATES_URL + id + '/actions/', actions);
}

export function deleteGateStep(id: number, gateStepId: number) {
  return axios.delete(GATES_URL + id + '/actions/' + gateStepId + '/');
}

export function enableGateStep(id: number, gateStepId: number) {
  return axios.post(GATES_URL + id + '/actions/' + gateStepId + '/enable/');
}

export function disableGateStep(id: number, gateStepId: number) {
  return axios.post(GATES_URL + id + '/actions/' + gateStepId + '/disable/');
}
