import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { CreateGate } from './components/CreateGate';
import { Overview } from './components/OverviewPage';
import { View } from './components/ViewPage';

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Gates',
    path: '/gates',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

const GatePage: React.FC = () => {
  return (
    <Routes>
      <Route
        path="overview"
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
            <RecoilRoot>
              <Overview />
            </RecoilRoot>
          </>
        }
      />
      <Route
        path="new/*"
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Create new gate</PageTitle>
            <RecoilRoot>
              <Suspense fallback="Loading...">
                <CreateGate></CreateGate>
              </Suspense>
            </RecoilRoot>
          </>
        }
      />
      <Route
        path="view/:id/*"
        element={
          <>
            <RecoilRoot>
              <Suspense fallback={'Loading ...'}>
                <View></View>
              </Suspense>
            </RecoilRoot>
          </>
        }
      />
      <Route index element={<Navigate to="/gates/overview" />} />
    </Routes>
  );
};

export default GatePage;
