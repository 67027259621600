/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useAuth } from '../../../../app/modules/auth';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { UserMenu } from '../user-menu/UserMenu';

const AsideUserMenu: FC = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <div className="d-flex flex-stack">
        {/* begin::Wrapper */}
        <div className="d-flex align-items-center">
          {/* begin::Avatar */}
          <div className="symbol symbol-circle symbol-40px">
            <div className="symbol-label fs-2 fw-semibold bg-info text-inverse-info">
              {currentUser?.first_name[0]}
              {currentUser?.last_name[0]}
            </div>
            {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='avatar' /> */}
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className="ms-2">
            <span className="text-gray-800 fs-6 fw-bolder lh-1">
              {currentUser?.first_name} {currentUser?.last_name}
            </span>
            {/* <span className='text-muted fw-bold d-block fs-7 lh-1'>Python Dev</span> */}
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className="ms-1">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary position-relative me-n2"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="false"
            data-kt-menu-placement="top-end"
          >
            <KTSVG path="/media/icons/duotune/coding/cod001.svg" className="svg-icon-1" />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  );
};

export { AsideUserMenu };
